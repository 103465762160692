import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classes from "../Style.module.scss";
import { Col, Row, Space } from "antd";
import classNames from "classnames";
import QueryHook from "utils/queryHook";
import { Button, Input, Select } from "components/shared";
import LabelForInput from "components/shared/LabelForInput";

const ChangeFilterBtn = () => {
	const { t } = useTranslation();
	const { MergeQueryParams, AddQueryParams } = QueryHook();
	const { handleSubmit, control, reset } = useForm();

	const onSubmit = (values: any) => {
		MergeQueryParams({ ...values });
	};

	const filterClear = () => {
		AddQueryParams({});
		reset();
	};

	return (
		<>
			<div
				className={classNames({
					[classes.filter_box]: true,
				})}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row gutter={[20, 20]}>
						<Col span={4}>
							<Controller
								name="newFm"
								control={control}
								render={({ field }) => <Input label={t("ФМ рақами")} {...field} />}
							/>
						</Col>
            <Col span={4}>
              <Controller name="oldFm" control={control} render={({ field }) => <Input label={t("Эски ФМ")} {...field} />} />
            </Col>
						<Col span={4}>
							<Controller
								name="status"
								control={control}
								render={({ field }) => (
									<LabelForInput label={t("Статус")}>
										<Select
											size="small"
											{...field}
											options={[
												{ value: "10", label: t("Yangi") },
												{ value: "30", label: t("Qabul qilindi") },
												{ value: "35", label: t("Rad qilindi") },
											]}
										/>
									</LabelForInput>
								)}
							/>
						</Col>
						<Col>
							<Space>
								<Button onClick={filterClear}>{t("Тозалаш")}</Button>
								<Button htmlType="submit" type="primary">
									{t("Қидириш")}
								</Button>
							</Space>
						</Col>
					</Row>
				</form>
			</div>
		</>
	);
};

export default ChangeFilterBtn;
