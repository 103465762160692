import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Input, Modal, Row, Select, message } from "antd";
import { get } from "lodash";
import * as yup from "yup";
import { request } from "services/api";
import { useNs412Q, useNs413Q } from "hooks/queries/general";

type Props = {
	data?: any;
	modal?: boolean;
	setModal?: any;
	refetch?: any;
};

const UpdateSection = ({ data, modal, setModal, refetch }: Props) => {
	const { t } = useTranslation();
	const schema = yup.object().shape({
		phone: yup.string().required(),
	});
	const {
		control,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	const ns412 = useNs412Q();
	const ns412List = get(ns412, "data.data.data", [])?.map((item: any) => ({
		value: item?.code,
		label: item?.name,
	}));
	const ns413 = useNs413Q(Number(watch("ns412")) || data?.ns412Code, {
		enabled: !!Number(watch("ns412") ?? !!data?.ns412Code),
	});

	const ns413List = get(ns413, "data.data.data", [])?.map((item: any) => ({
		value: item?.code,
		label: item?.name,
	}));

	useEffect(() => {
		reset({
			...data,
			ns412: { value: data?.ns412Code, label: data?.ns412Name },
			ns413: { value: data?.ns413Code, label: data?.ns413Name },
		});
	}, [data]);

	const updateData = useMutation((body: any) => request.put(`/sale-point/update-data`, body), {
		onSuccess: (res: any) => {
			if (res?.data?.success) {
				message.success(t(`Муваффақиятли қўшилди`));
				refetch && refetch();
				setModal(false);
			}
		},

		onError: (err: any) => {
			message.error(err);
		},
	});

	const onSubmit = async (data: any) => {
		const { id, name, phone, ns412, ns413 } = data;
		await updateData.mutateAsync({
			id,
			name,
			phone: `+998${String(phone).replaceAll(" ", "")}`,
			ns412Code: ns412?.value ?? ns412,
			ns413Code: ns413?.value ?? ns413,
		});

		reset();
		setModal(false);
	};

	const showModal = () => {
		setModal(true);
	};

	const handleOk = () => {
		setModal(false);
	};

	const handleCancel = () => {
		setModal(false);
	};

	const prefixSelector = <span>+998</span>;

	return (
		<>
			<Modal open={modal} onOk={handleOk} width={700} footer={false} onCancel={handleCancel} centered>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div>
						<h3>{t("Маълумот ўзгартириш")}</h3>
						<Row gutter={[20, 20]}>
							<Col span={12}>
								<p>{t("Номи")}</p>
							</Col>
							<Col span={12}>
								<Controller name="name" control={control} render={({ field }: any) => <Input {...field} />} />
							</Col>
							<Col span={12}>
								<p>{t("Телефон рақам")}</p>
							</Col>
							<Col span={12}>
								<Controller
									name="phone"
									control={control}
									render={({ field }: any) => (
										<>
											<PatternFormat {...field} customInput={Input} format={"## ### ## ##"} size="middle" prefix="+998" />
											<p style={{ color: "red" }}>{errors.phone?.message}</p>
										</>
									)}
								/>
							</Col>
							<Col span={12}>
								<p>{t("Фаолият тури")}</p>
							</Col>
							<Col span={12}>
								<Controller
									name="ns412"
									control={control}
									render={({ field }) => <Select style={{ width: "100%" }} {...field} options={ns412List} />}
								/>
							</Col>
							<Col span={12}>
								<p>{t("Фаолият тоифаси")}</p>
							</Col>
							<Col span={12}>
								<Controller
									name="ns413"
									control={control}
									render={({ field }) => <Select style={{ width: "100%" }} {...field} options={ns413List} />}
								/>
							</Col>
						</Row>

						<div className="h-[20px]" />

						<div className="flex items-center !gap-3">
							<Button block onClick={() => setModal(false)}>
								{t("Бекор қилиш")}
							</Button>
							<Button block type="primary" htmlType="submit" loading={updateData.isLoading}>
								{t("Юбориш")}
							</Button>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};

export default UpdateSection;
