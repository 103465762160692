import { useTranslation } from "react-i18next";
import { Segmented } from "antd";
import useAutoReducer from "hooks/useAutoReducer";
import ApplicationTxkmPartners from "pages/Application/Txkm/ApplicationTxkmPartners";
import AddTxkmEmployeeModal from "pages/Application/Txkm/AddTxkmEmployeeModal";
import AddTxkmFilialModal from "pages/Application/Txkm/AddTxkmFilialModal";
import AddTxkmPartnerModal from "pages/Application/Txkm/AddTxkmPartnerModal";
import ApplicationTxkmEmployeeList from "pages/Application/Txkm/ApplicationTxkmEmployeeList";
import ApplicationTxkmFilials from "pages/Application/Txkm/ApplicationTxkmFilials";
import { find, get } from "lodash";
import { IUser, useUser } from "components/shared/Layout/Header/Header";

type TTxkmListTabs = "users" | "filial" | 'partners';

const ApplicationTxkmList = () => {
	const { t } = useTranslation();

	const [state, dispatch] = useAutoReducer({
		tab: "users" as TTxkmListTabs,
	});

	const token = localStorage.getItem("token");

        const queryUser = useUser({ token });
        const user = get(queryUser, "data.data.data", {}) as IUser;

        const activeCompany = find(get(user, "myCompanies", []), (company) => {
            return company.selected === 1;
        });
        const role = get(activeCompany, "roleId", 5);

	return (
		<div>
			<div className="flex justify-between gap-20">
				<div>
					<Segmented
						onChange={(tab) => dispatch({ param: tab as TTxkmListTabs, type: "tab" })}
						value={state.tab}
						options={[
							{
								label: <div style={{ padding: 4, width: 200 }}>{t("Ходимлар")}</div>,
								value: "users" satisfies TTxkmListTabs,
							},
                            ...role !== 5 ? [

                                {
                                    label: <div style={{ padding: 4, width: 200 }}>{t("Филиал")}</div>,
                                    value: "filial" satisfies TTxkmListTabs,
                                },
                                {
                                    label: <div style={{ padding: 4, width: 200 }}>{t("Xамкорлар")}</div>,
                                    value: "partners" satisfies TTxkmListTabs,
                                },
                            ]: []
						]}
					/>
				</div>


				<div className="flex items-center !gap-5">
					<AddTxkmEmployeeModal />

                {role === 5 ? null : (
                        <>

                                <AddTxkmFilialModal />

                                            <AddTxkmPartnerModal />
                        </>
                ) }
				</div>
			</div>

			<div className="h-[20px]" />


			{(() => {
				switch (state.tab) {
					case "users":
						return <ApplicationTxkmEmployeeList />;
					case "filial":
						return <ApplicationTxkmFilials />;
					case "partners":
						return <ApplicationTxkmPartners />;
					default:
						return null;
				}
			})()}
		</div>
	);
};

export default ApplicationTxkmList;
