import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classes from "./Header.module.scss";
import { get } from "lodash";
import { req } from "services/api";
import Language from "../components/Language";
import Offerta from "../components/Offerta";
import Profile from "../components/Profile";
import { ReactComponent as Logo } from "assets/images/svg/common/logo.svg";

export interface IUser {
	lastname: string;
	firstname: string;
	tin: string;
	pinfl: string | null;
	menu: number;
	ctoCompanyName: string;
	ctoCompanyTin: string;
	myCompanies: {
		id: number;
		parentName: null;
		name: string;
		tin: string;
		isActive: number;
		selected: number;
		userId: number;
		roleId: number;
		roleName: string;
	}[];
}

export const useUser = ({ token }: { token: string | null }) => {
	const navigate = useNavigate();
	return useQuery({
		queryKey: ["queryUser--get-me", token],
		queryFn: () =>
			req("reestr")({ url: `/user/get-me` }).catch((res) => {
				if (String(res.response.status) === "401" || String(res.response.status) === "505") {
					return navigate(`/reestr/sale-point`);
				}
			}),
	});
};

const Header = () => {
	const { t, i18n } = useTranslation();
	const token = localStorage.getItem("token");

	const queryUser = useUser({ token });

	const user = get(queryUser, "data.data.data", {}) as IUser;
	const roleName = get(
		(get(user, "myCompanies", []) ?? []).find((mycompany: any) => String(mycompany.selected) === "1"),
		"roleName",
		"-"
	);

	return (
		<header className={`${classes.header}`}>
			<div className={classes.bg}></div>

			<div className="!px-5">
				<div className={classes.header_top}>
					<div className={classes.left}>
						<span>
							<Logo />
							<span>{t("Онлайн-НКМ ёки виртуал кассаларни рўйхатга олиш тизими")}</span>
						</span>
					</div>
					<div className={classes.right}>
						<Language />

						<Offerta />

						<Profile
							fio={
								<>
									<p className="!text-xs !my-0">{get(user, "ctoCompanyName", "-")}</p>

									<p className="!text-xs !my-0">{roleName}</p>

									<p className="!text-xs !my-0">{user ? (user.tin ? user.tin : user.pinfl) : ""}</p>

									<p className="!text-xs !my-0">
										{get(user, "firstname", null)} {get(user, "lastname", null)}
									</p>
								</>
							}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
