import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ApplicationTxkmChangeList from "../TxkmChange/ApplicationTxkmChangeList";
import ApplicationDSI from "./ApplicationDSI";
import { Badge, TabsProps } from "antd";
import classNames from "classnames";
import { find, get } from "lodash";
import ApplicationTxkmList from "pages/Application/Txkm/ApplicationTxkmList";
import { req } from "services/api";
import { IUser, useUser } from "components/shared/Layout/Header/Header";
import FmChange from "pages/Application/components/FmChange";
import NkmNosoz from "pages/Application/components/NkmNosoz";

export const useQueryNotificationCount = () => {
	return useQuery({
		queryKey: ["queryNotificationCount"],
		queryFn: () => {
			return req("reestr")({
				url: `/application-invalid-cash/notification`,
				params: {},
			});
		},
	});
};

const TabSection = () => {
	const { t } = useTranslation();
	const { page: pageId } = useParams();

	const token = localStorage.getItem("token");

	const queryUser = useUser({ token });
	const user = get(queryUser, "data.data.data", {}) as IUser;
	const queryNotificationCount = useQueryNotificationCount();
	const noitifcation_count = get(queryNotificationCount, "data.data.recordsTotal", 0);

	const activeCompany = find(get(user, "myCompanies", []), (company) => {
		return company.selected === 1;
	});
	const role = get(activeCompany, "roleId", 5);

	const isAdmin = role === 4;

	const pages: TabsProps["items"] = [
		{
			key: "dsi",
			label: t(`ДСИ аризалари`),
			children: <ApplicationDSI />,
		},
		{
			key: "txkm-almashtirish",
			label: t(`ТХКМ алмаштириш`),
			children: <ApplicationTxkmChangeList />,
		},

		...(role === 5
			? [
					{
						key: "txkm",
						label: t(`ТХКМ аризалар`),
						children: <ApplicationTxkmList />,
					},
					{
						key: "fm-almashtirish",
						label: t(`ФМ алмаштириш аризалари`),
						children: <FmChange />,
					},
			  ]
			: []),
		...(isAdmin
			? [
					{
						key: "txkm",
						label: t(`ТХКМ аризалар`),
						children: <ApplicationTxkmList />,
					},
					{
						key: "fm-almashtirish",
						label: t(`ФМ алмаштириш аризалари`),
						children: <FmChange />,
					},
					{
						key: "nosoz-nkm",
						label: t(`Носоз ҳолга келган Онлайн-НКМ`),
						children: <NkmNosoz />,
					},
			  ]
			: []),
	];

	const activeTab = pages.find((page) => page.key === pageId)?.children;

	return (
		<div className="!p-5">
			<div className="flex items-center !text-sm !gap-3">
				{pages.map((page, i) => {
					return (
						<>
							<Link to={`/application/${page.key}`} className="flex-grow">
								<Badge count={page.key === "nosoz-nkm" ? noitifcation_count : 0} showZero={false} className="w-full">
									<button
										className={classNames({
											["!px-7 !py-4 rounded-md duration-200 !w-full"]: true,
											["bg-primary-def text-white"]: page.key === pageId,
										})}
									>
										{page.label}
									</button>
								</Badge>
							</Link>
						</>
					);
				})}
			</div>

			<div className="h-[30px]" />

			{activeTab}
		</div>
	);
};

export default TabSection;
