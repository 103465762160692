import { GeolocationControl, SearchControl } from "@pbe/react-yandex-maps";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useReducer, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Steps, message, notification } from "antd";
import { get, toPairs } from "lodash";
import { req, request } from "services/api";
import YMap from "components/YMap";
import BinoForm from "pages/Rester/components/BranchesView/BinoForm";
import FiskalForm from "pages/Rester/components/BranchesView/FiskalForm";
import ImzolashForm from "pages/Rester/components/BranchesView/ImzolashForm";
import KorxonaForm from "pages/Rester/components/BranchesView/KorxonaForm";
import YakuniyForm from "pages/Rester/components/BranchesView/YakuniyForm";

enum ESteps {
	"Korxona" = 0,
	"Bino" = 1,
	"Shoxobcha" = 2,
	"Fiskal" = 3,
	"Imzolash" = 4,
	"Yakuniy" = 5,
}

function stepsMachine(state: ESteps, payload: { type: "NEXT" | "PREV" | ESteps }): ESteps {
	if (payload.type === "NEXT") {
		switch (state) {
			case ESteps.Korxona:
				return ESteps.Bino;
			case ESteps.Bino:
				return ESteps.Shoxobcha;
			case ESteps.Shoxobcha:
				return ESteps.Fiskal;
			case ESteps.Fiskal:
				return ESteps.Imzolash;
			case ESteps.Imzolash:
				return ESteps.Yakuniy;
			case ESteps.Yakuniy:
				return ESteps.Yakuniy;
			default: {
				const typeState: never = state;

				console.log(`Error: ${typeState}`);
			}
		}

		return state;
	}

	if (payload.type === "PREV") {
		switch (state) {
			case ESteps.Korxona:
				return ESteps.Korxona;
			case ESteps.Bino:
				return ESteps.Korxona;
			case ESteps.Shoxobcha:
				return ESteps.Bino;
			case ESteps.Fiskal:
				return ESteps.Shoxobcha;
			case ESteps.Imzolash:
				return ESteps.Fiskal;
			case ESteps.Yakuniy:
				return ESteps.Imzolash;
			default: {
				const typeState: never = state;

				console.log(`Error: ${typeState}`);
			}
		}

		return state;
	}

	return payload.type;
}

function AddApplication() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [step, dispatch] = useReducer(stepsMachine, ESteps.Korxona);
	const [latitude, setLatitude] = useState<number | null>(41.2995);
	const [longitude, setLongitude] = useState<number | null>(69.2401);

	const mutateAddFmo = useMutation({
		mutationKey: ["mutateAddFmo"],
		mutationFn: (data: FormData) => {
			return req("reestr")({
				url: `/application-fmo/create`,
				method: "POST",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				data: data,
			});
		},
	});

	const checkLocation = useMutation({
		mutationKey: ["checkLocation"],
		mutationFn: (data: { ns10Code: number; ns11Code?: number; latitude: number; longitude: number }) => {
			return req("reestr")({
				url: `/information/check/location`,
				method: "POST",
				data: data,
			});
		},
	});

	const locationChangeRef = useRef<any>(null);

	const methods = useForm<any>({
		defaultValues: {
			reestr: "new",
			tin: "",
			typeObject: 1,
			passes_fiskal: false,
		},
	});

	const onSubmit = async (values: any) => {
		try {
			const tinOrPinfl = (() => {
				try {
					return get(values, "tin", "").length === 9 ? "tin" : "pinfl";
				} catch (error) {
					return "tin";
				}
			})();

			const form = {
				phone: (get(values, "phone", "") || "").replaceAll(" ", ""),
				[tinOrPinfl]: get(values, "tin", ""),
				mutualFiles: get(values, "ozaro_ariza_file", ""),
				salePointId:
					get(values, "reestr", "new") === "new" ? methods.getValues("sale-point-add-api.id") : get(values, "reestr", null),
				kadastrFile: get(values, "objDocFileId"),
				kkmCategoryId: get(values, "onlayn_nkm", ""),

				saleNs10Code: get(values, "view_viloyat", ""),
				saleNs11Code: get(values, "view_tuman", ""),

				saleLongitude: longitude,
				saleLatitude: latitude,
				kkmSerialNumber: get(values, "onlayn_nkm__vk_seriya", ""),
				saleAddress: get(values, "saleAddress", ""),
				saleName: get(values, "name", ""),

				saleNs412Code: get(values, "faoliyat_turi", ""),
				saleNs413Code: get(values, "shoxobcha_toifasi", ""),
				terminalId: get(values, "fiskal_modul_seariya", ""),

				signedFiles: get(values, "imzolangan_ariza_file", ""),
				scannerFiles: get(values, "2d_skaner_file", ""),
				scannerCode: get(values, "2d_skanner", ""),
				typeObject: get(values, "typeObject", ""),

				arendaNumber: get(values, "arendaNumber", null),
				kadastrNumber: get(values, "kadastrNumber", null),
				arendaBeginDate: get(values, "arendaBeginDate", null),
				arendaEndDate: get(values, "arendaEndDate", null),
				saleNeighborhoodCode: get(values, "saleNeighborhoodCode", null),
			};

			const formData = new FormData();
			Object.entries(form).forEach(([key, value]) => {
				if (value) {
					formData.append(key, value);
				}
			});

			const res = await mutateAddFmo.mutateAsync(formData);

			const success = get(res, "data.success", false);

			if (!success) {
				const errorCode = get(res, "data.code", "-");

				message.error(t(errorCode));
			} else {
				message.success(t(`Qo'shildi`));
				window.location.href = "/application/dsi";
			}
		} catch (error) {
			//
		}
	};

	const selectedReestr = methods.watch("reestr");

	const checkMapSelectionIsAvailable = ({ lat, long }: { lat: number; long: number }) => {
		setLatitude(lat);
		setLongitude(long);

		const ns10Code = methods.getValues("view_viloyat");
		const ns11Code = methods.getValues("view_tuman");

		checkLocation.mutate(
			{
				latitude: lat,
				longitude: long,
				ns10Code,
				// ns11Code,
			},
			{
				onSuccess(res) {
					if (res.data.success) {
						setLatitude(lat);
						setLongitude(long);

						methods.setValue("latitude", lat);
						methods.setValue("longitude", long);
					} else {
						notification.error({ message: t("Харитада танланган вилоят ҳудудидан кординаталар кўрсатилиши керак") });
					}
				},
			}
		);
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)} className="!p-10">
				<Steps
					current={step}
					items={[
						{
							title: t(`Корхона маълумотлари`),
						},
						{
							title: t(`Бино маълумотлари`),
						},
						{
							title: t("Харитадаги координаталари"),
						},
						{
							title: t("Фискал модул бириктириш"),
						},
						{
							title: t("Аризани имзолаш"),
						},
						{
							title: t("Якуний қисм"),
						},
					]}
				/>

				<div className="h-[20px]" />

				{(() => {
					if (step === ESteps.Korxona) {
						return <KorxonaForm />;
					}

					if (step === ESteps.Bino) {
						return <BinoForm />;
					}

					if (step === ESteps.Shoxobcha) {
						return (
							<div className="!h-[500px] !w-full">
								<YMap
									latitude={latitude}
									longitude={longitude}
									setLat={setLatitude}
									setLong={setLongitude}
									onClick={(e: any) => {
										const [lat, long] = e.get("coords");
										checkMapSelectionIsAvailable({ lat, long });
									}}
								>
									<SearchControl options={{ float: "right" }} />

									<GeolocationControl instanceRef={locationChangeRef} options={{ float: "left" }} />
								</YMap>
							</div>
						);
					}

					if (step === ESteps.Fiskal) {
						return <FiskalForm />;
					}

					if (step === ESteps.Imzolash) {
						return <ImzolashForm />;
					}

					if (step === ESteps.Yakuniy) {
						return <YakuniyForm />;
					}
				})()}

				<div className="h-[20px]" />

				<div className="flex items-center justify-center !gap-5 w-1/2 mx-auto">
					<Button block disabled={step === ESteps.Korxona} onClick={() => dispatch({ type: "PREV" })}>
						{t("Олдингиси")}
					</Button>

					<Button
						block
						type="primary"
						htmlType={step === ESteps.Yakuniy ? "submit" : "button"}
						disabled={(() => {
							if (step === ESteps.Fiskal) {
								return !methods.watch("passes_fiskal");
							}

							return false;
						})()}
						onClick={async () => {
							const passes = await methods.trigger();

							if (methods.getValues("reestr") === "new" && step === ESteps.Fiskal) {
								//
							}

							if (step === ESteps.Korxona && !methods.getValues("is_inn_entered")) {
								message.error(t("Tekshirishni bosing!"));
								return;
							}

							if (methods.getValues("reestr") !== "new" && step === ESteps.Korxona) return dispatch({ type: ESteps.Fiskal });

							if (passes) {
								dispatch({ type: "NEXT" });
							}
						}}
					>
						{t("Кейингиси")}
					</Button>
				</div>
			</form>
		</FormProvider>
	);
}

export default AddApplication;
