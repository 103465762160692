import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Alert, Button } from "antd";
import { get } from "lodash";
import { req } from "services/api";
import { Input, Select } from "components/shared";
import ErrorText from "pages/Rester/components/BranchesView/ErrorText";

function FiskalForm() {
	const { t } = useTranslation();
	const {
		control,
		getValues,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
		watch,
	} = useFormContext();
	const tin = watch("tin");

	const queryNkmList = useQuery({
		queryKey: ["queryNkmList", tin],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/cto-kkm-category/index/active`,
				params: {
					tin,
				},
			});
		},
	});
	const nkmList = get(queryNkmList, "data.data.data", []) as { id: number; name: string }[];

	const queryFiskalSeriya = useQuery({
		queryKey: ["queryFiskalSeriya"],
		queryFn: () => {
			return req("reestr")({
				method: "GET",
				url: `/ofd-main/scanner-list`,
			});
		},
	});
	const fiskalSeriya = get(queryFiskalSeriya, "data.data.data", []) as {
		code: number;
		name: string;
	}[];

	const mutateCheckFm = useMutation({
		mutationKey: ["mutateCheckFm"],
		mutationFn: () => {
			return req("reestr")({
				url: `/information/check-fm`,
				params: {
					terminalId: getValues("fiskal_modul_seariya"),
					tin: getValues("tin"),
					kkmCategoryId: getValues("onlayn_nkm"),
				},
			});
		},
	});
	const checkFm = get(mutateCheckFm, "data.data");

	const mutateCheckSerialNum = useMutation({
		mutationKey: ["mutateCheckSerialNum"],
		mutationFn: () => {
			return req("reestr")({
				url: `/information/check-serial-number`,
				params: {
					kkmCategoryId: getValues("onlayn_nkm"),
					serialNumber: getValues("onlayn_nkm__vk_seriya"),
				},
			});
		},
	});
	const checkSerialNum = get(mutateCheckSerialNum, "data.data");

	const FALLBACK = "falback_2001";
	const serialNumberPasses = get(checkSerialNum, "success", FALLBACK);
	const fmPasses = get(checkFm, "success", FALLBACK);

	useEffect(() => {
		if (!serialNumberPasses) {
			setError("checkSerialNum", {
				type: "manual",
				message: get(checkSerialNum, "code"),
			});
		} else {
			clearErrors("checkSerialNum");
		}

		if (!fmPasses) {
			setError("checkFm", {
				type: "manual",
				message: get(checkFm, "code"),
			});
		} else {
			clearErrors("checkFm");
		}
		if (serialNumberPasses && fmPasses && mutateCheckSerialNum.status !== "idle" && mutateCheckFm.status !== "idle") {
			setValue("passes_fiskal", true);
		}
	}, [serialNumberPasses, checkFm]);

	return (
		<div className="flex !gap-5">
			<div className="border !border-gray-400 rounded-lg w-1/2 !p-5 space-y-7" style={{ border: "1px solid" }}>
				<Controller
					control={control}
					rules={{ required: true }}
					name="onlayn_nkm"
					render={({ field }) => {
						return (
							<div>
								<Select
									options={nkmList.map((nkm) => ({
										label: nkm.name,
										value: nkm.id,
									}))}
									label={t("Онлайн-НКМ категорияси")}
									{...field}
								/>

								<ErrorText>{get(errors, "onlayn_nkm", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
							</div>
						);
					}}
				/>

				<div className="flex !gap-5">
					<Controller
						control={control}
						rules={{ required: true }}
						name="onlayn_nkm__vk_seriya"
						render={({ field }) => {
							return (
								<div className="!w-full">
									<Input
										label={t("Онлайн-НКМ завод ёки ВК серия рақами")}
										className="!w-full flex-grow"
										size="large"
										width={`100%`}
										{...field}
									/>
									<ErrorText>{get(errors, "onlayn_nkm__vk_seriya", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
								</div>
							);
						}}
					/>

					<Button onClick={() => mutateCheckSerialNum.mutate()}>{t("Текшириш")}</Button>
				</div>

				{serialNumberPasses === FALLBACK ? null : serialNumberPasses ? (
					<Alert showIcon type="success" description={t("Успешно")} />
				) : (
					<div>
						<Alert
							className="text-red-500"
							showIcon
							type="error"
							description={t(get(errors, "checkSerialNum.message", "-") as string)}
						/>
					</div>
				)}
			</div>

			<div className="border !border-gray-400 rounded-lg w-1/2 !p-5 space-y-7" style={{ border: "1px solid" }}>
				<Controller
					control={control}
					// rules={{ required: true }}
					name="2d_skanner"
					render={({ field }) => {
						return (
							<div>
								<Select
									options={fiskalSeriya.map((seriya) => ({
										label: seriya.name,
										value: seriya.code,
									}))}
									label={t("2Д сканнер тури")}
									{...field}
								/>
								<ErrorText>{get(errors, "2d_skanner", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
							</div>
						);
					}}
				/>

				<div className="flex !gap-5">
					<Controller
						control={control}
						rules={{ required: true }}
						name="fiskal_modul_seariya"
						render={({ field }) => {
							return (
								<div className="!w-full">
									<Input
										label={t("Фискал модул серия рақами")}
										className="!w-full flex-grow"
										size="large"
										width={`100%`}
										{...field}
									/>
									<ErrorText>{get(errors, "fiskal_modul_seariya", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
								</div>
							);
						}}
					/>

					<Button onClick={() => mutateCheckFm.mutate()}>{t("Текшириш")}</Button>
				</div>

				{fmPasses === FALLBACK ? null : fmPasses ? (
					<Alert showIcon type="success" description={t("Успешно")} />
				) : (
					<div>
						<Alert
							className="text-red-500"
							showIcon
							type="error"
							description={t(get(errors, "checkFm.message", "-") as string)}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default FiskalForm;
