import { useMutation } from "@tanstack/react-query";
import { UploadCloud } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Description } from "@geist-ui/core";
import { Button, DatePicker, Radio, Upload, message } from "antd";
import { MaskedInput } from "antd-mask-input";
import dayjs from "dayjs";
import { get, isEmpty } from "lodash";
import { req } from "services/api";
import { Input } from "components/shared";
import ErrorText from "pages/Rester/components/BranchesView/ErrorText";
import SelectMaxalla from "pages/Rester/components/BranchesView/SelectMaxalla";
import SelectTuman from "pages/Rester/components/BranchesView/SelectTuman";
import SelectViloyat from "pages/Rester/components/BranchesView/SelectViloyat";

export default function BinoForm() {
	const { t } = useTranslation();
	const {
		control,
		watch,
		resetField,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = useFormContext();

	const [radio, setRadio] = useState(getValues("typeObject"));

	// intentional re-render
	watch("view_viloyat");

	const mutateKadastr = useMutation({
		mutationKey: ["mutateKadastr"],
		mutationFn: (params: object) => {
			return req("reestr")({
				url: `/information/get-cadastre`,
				params,
			});
		},
	});

	const onKadastr = async () => {
		try {
			const tinOrPinfl = getValues("tin").length === 9 ? "tin" : "pinfl";

			const res = await mutateKadastr.mutateAsync({
				[tinOrPinfl]: getValues("tin"),
				cadastreNumber: getValues("kadastrNumber"),
			});

			const data = get(res, "data.data", null) as {
				address: string;
				ns10Code: number;
				ns11Code: number;
			};

			if (!isEmpty(data)) {
				setValue("view_viloyat", data.ns10Code);
				setValue("view_tuman", data.ns11Code);
				setValue("view_address", data.address);
			}
		} catch (error) {
			//
		}
	};

	const mutateArenda = useMutation({
		mutationKey: ["mutateArenda"],
		mutationFn: (params: object) => {
			return req("reestr")({
				url: `/information/get-rent`,
				params,
			});
		},
	});

	const onArenda = async () => {
		try {
			const tinOrPinfl = getValues("tin").length === 9 ? "tin" : "pinfl";

			const res = await mutateArenda.mutateAsync({
				[tinOrPinfl]: getValues("tin"),
				rentNumber: getValues("arendaNumber"),
				beginDate: getValues("arendaBeginDate"),
				endDate: getValues("arendaEndDate"),
			});

			const data = get(res, "data.data", null) as {
				address: string;
				ns10Code: number;
				ns11Code: number;
			};

			if (!isEmpty(data)) {
				setValue("view_viloyat", data.ns10Code);
				setValue("view_tuman", data.ns11Code);
				setValue("view_address", data.address);
			}

			const success = get(res, "data.success", false);

			if (!success) {
				const errorCode = get(res, "data.code", 1001);

				console.log(errorCode);

				message.error(t(errorCode));
			}
		} catch (error) {
			//
		}
	};

	return (
		<div>
			<div className="border !border-gray-400 rounded-lg w-full !p-5 space-y-7" style={{ border: "1px solid" }}>
				<h3 className="block text-lg text-center text-blue-700">{t("Шаҳобча объектини тасдиқлаш турини танланг")}</h3>

				<div className="gap-5">
					<Radio.Group
						className="flex items-center !gap-5"
						value={radio}
						onChange={(e) => {
							resetField("arendaNumber");
							resetField("kadastrNumber");
							resetField("viloyat");
							resetField("tuman");
							resetField("saleNeighborhoodCode");

							setValue("view_viloyat", undefined);
							setValue("view_tuman", undefined);
							setValue("saleNeighborhoodCode", undefined);
							setValue("view_address", undefined);

							setValue("typeObject", e.target.value);
							setRadio(e.target.value);
						}}
					>
						<Radio value={1} className="flex items-center">
							{t("Шахсий мулк(кадастр рақами асосида текшириш)")}
						</Radio>
						<Radio value={2} className="flex items-center">
							{t("ДСИдан рўйхатдан ўтказилган ижара шартномаси рақами асосида текшириш")}
						</Radio>
					</Radio.Group>
				</div>

				<div className="h-[1px] bg-gray-500" />

				{radio === 1 ? (
					<>
						<Description
							title={t("Кадастр рақами")}
							content={
								<>
									<div className="flex gap-5">
										<div>
											<Controller
												control={control}
												name={t("kadastrNumber")}
												rules={{ required: true }}
												render={({ field }) => {
													return (
														<>
															{false ? (
																<Input
																	className="w-64"
																	value={field.value}
																	onChange={(e) => field.onChange(e.currentTarget.value)}
																/>
															) : (
																<MaskedInput
																	mask={"00:00:00:00:00:0000`[******************************]"}
																	className="w-64"
																	value={field.value}
																	onChange={(e) => field.onChange(e.maskedValue)}
																/>
															)}
														</>
													);
												}}
											/>

											<ErrorText>{get(errors, "kadastrNumber", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
										</div>
										<Button onClick={onKadastr}>{t("Текшириш")}</Button>
									</div>
								</>
							}
						/>

						<div className="grid grid-cols-5 !gap-2">
							<SelectViloyat disabled value={getValues("view_viloyat")} />
							<SelectTuman disabled selectedViloyat={getValues("view_viloyat")} value={getValues("view_tuman")} />

							<Controller
								name="saleNeighborhoodCode"
								control={control}
								rules={{ required: true }}
								render={({ field }) => {
									return (
										<div>
											<SelectMaxalla
												selectedViloyat={getValues("view_viloyat")}
												selectedTuman={getValues("view_tuman")}
												{...field}
											/>
											<ErrorText>{get(errors, "saleNeighborhoodCode", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
										</div>
									);
								}}
							/>

							<Input className="w-full" label={t("Манзил")} size="large" readOnly value={getValues("view_address")} />

							<Controller
								name="objDocFileId"
								control={control}
								rules={{ required: true }}
								render={({ field }) => {
									return (
										<Upload
											multiple={false}
											beforeUpload={(file) => {
												setValue("objDocFileId", file);

												return false;
											}}
											onRemove={() => {
												setValue("objDocFileId", undefined);

												return true;
											}}
										>
											<Button
												block
												type="dashed"
												className="flex items-center justify-center gap-3"
												icon={<UploadCloud strokeWidth={2} height={16} width={16} />}
											>
												{t("Ҳужжатларни юкланг")}
											</Button>
											<ErrorText>{get(errors, "objDocFileId", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
										</Upload>
									);
								}}
							/>
						</div>
					</>
				) : null}

				{radio === 2 ? (
					<>
						<>
							<div className="flex items-end gap-5">
								<Description
									title={t("Ижара шартнома рақамини киритинг")}
									content={
										<div>
											<Controller
												control={control}
												name={t("arendaNumber")}
												rules={{ required: true }}
												render={({ field }) => {
													return (
														<Input
															prefix={"ID-"}
															className="w-72"
															placeholder={t("Ижара шартнома рақамини киритинг")}
															{...field}
														/>
													);
												}}
											/>

											<ErrorText>{get(errors, "arendaNumber", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
										</div>
									}
								/>

								<Description
									title={t("Ижара шартнома маълумотларини киритинг")}
									content={
										<div>
											<Controller
												control={control}
												name="arendaBeginDate"
												render={({ field, formState }) => {
													return (
														<DatePicker.RangePicker
															format={"DD.MM.YYYY"}
															className="w-full"
															placeholder={["", ""]}
															value={
																getValues("arendaBeginDate")
																	? [
																			dayjs(getValues("arendaBeginDate"), "DD.MM.YYYY"),
																			dayjs(getValues("arendaEndDate"), "DD.MM.YYYY"),
																	  ]
																	: undefined
															}
															onChange={(e) => {
																if (e) {
																	const from = e[0]?.format("DD.MM.YYYY");
																	const to = e[1]?.format("DD.MM.YYYY");

																	setValue("arendaBeginDate", from);
																	setValue("arendaEndDate", to);
																}
															}}
														/>
													);
												}}
											/>

											<ErrorText>{get(errors, "kadastrNumber", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
										</div>
									}
								/>

								<Button onClick={onArenda}>{t("Текшириш")}</Button>
							</div>

							<div className="grid grid-cols-5 !gap-2">
								<SelectViloyat disabled value={getValues("view_viloyat")} />
								<SelectTuman disabled selectedViloyat={getValues("view_viloyat")} value={getValues("view_tuman")} />

								<Controller
									name="saleNeighborhoodCode"
									control={control}
									rules={{ required: true }}
									render={({ field }) => {
										return (
											<div>
												<SelectMaxalla
													selectedViloyat={getValues("view_viloyat")}
													selectedTuman={getValues("view_tuman")}
													{...field}
												/>
												<ErrorText>{get(errors, "saleNeighborhoodCode", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
											</div>
										);
									}}
								/>

								<Input className="w-full" label={t("Манзил")} size="large" readOnly value={getValues("view_address")} />

								<Controller
									name="objDocFileId"
									control={control}
									rules={{ required: true }}
									render={({ field }) => {
										return (
											<Upload
												multiple={false}
												beforeUpload={(file) => {
													setValue("objDocFileId", file);

													return false;
												}}
												onRemove={() => {
													setValue("objDocFileId", undefined);

													return true;
												}}
											>
												<Button
													block
													type="dashed"
													className="flex items-center justify-center gap-3"
													icon={<UploadCloud strokeWidth={2} height={16} width={16} />}
												>
													{t("Ҳужжатларни юкланг")}
												</Button>
												<ErrorText>{get(errors, "objDocFileId", null) ? t("Тўлдириш шарт!") : null}</ErrorText>
											</Upload>
										);
									}}
								/>
							</div>
						</>
					</>
				) : null}
			</div>
		</div>
	);
}
