import { useTranslation } from "react-i18next";
import { Segmented } from "antd";
import { find, get } from "lodash";
import useAutoReducer from "hooks/useAutoReducer";
import { IUser, useUser } from "components/shared/Layout/Header/Header";
import ChangeFilterBtn from "pages/Application/components/ChangeFilterBtn";
import TabDefaultFmChange from "pages/Application/components/TabDefaultFmChange";
import TabFilialFmChange from "pages/Application/components/TabFilialFmChange";

type TFmTabs = "default" | "filial";
function FmChange() {
	const { t } = useTranslation();

	const token = localStorage.getItem("token");

	const queryUser = useUser({ token });
	const user = get(queryUser, "data.data.data", {}) as IUser;

	const activeCompany = find(get(user, "myCompanies", []), (company) => {
		return company.selected === 1;
	});
	const role = get(activeCompany, "roleId", 5);

	const [state, dispatch] = useAutoReducer<{
		tab: TFmTabs;
	}>({
		tab: "default",
	});
	return (
		<div>
			<Segmented
				size="large"
				value={state.tab}
				onChange={(value) => dispatch({ type: "tab", param: value as TFmTabs })}
				options={[
					{
						label: t("ФМ алмаштириш аризалари"),
						value: "default",
					},
					...(role === 5
						? []
						: [
								{
									label: t("Филиаллар ва шериклар ФМ алмаштриш аризалари"),
									value: "filial",
								},
						  ]),
				]}
			/>
			<div className="h-[20px]" />
			<ChangeFilterBtn />
			<div className="h-[20px]" />

			{state.tab === "default" ? <TabDefaultFmChange /> : null}
			{state.tab === "filial" ? <TabFilialFmChange /> : null}
		</div>
	);
}

export default FmChange;
